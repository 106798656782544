import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { companyBankInfoSchema } from "../../../../utils/validationSchemas";
import { CustomButton } from "../../../../globalComponents";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, Elements } from "@stripe/react-stripe-js";

import "./index.css";
import { postRequest } from "../../../../service/apiClient";
import { saveBankInfo } from "../../../../service/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_STRIPE_LIVE
    : process.env.REACT_APP_STRIPE_TEST
);

const CompanyBankInformation = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let { routingNo, bankAccountNo, ssnLastFourDigits } = values;
      let {
        token: { id: bankToken },
      } = await stripe.createToken("bank_account", {
        country: "US",
        currency: "usd",
        routing_number: routingNo,
        account_number: bankAccountNo,
      });

      if (!bankToken) {
        toast.error("Something went wrong");
        setLoading(false);
      }
      let params = {
        bankToken,
        ssn_last_4: ssnLastFourDigits,
        routingNumber: routingNo,
        accountNumber: bankAccountNo,
      };
      let {
        data: { success, message },
      } = await postRequest(saveBankInfo, params);

      setLoading(false);
      if (success) {
        navigate("/dashboard");
        toast.success(message);
      } else {
        toast.warn(message);
      }
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div className="company_info_container">
      <h2 className="form_heading">Company Bank Information </h2>
      <Formik
        initialValues={{
          bankAccountNo: "",
          routingNo: "",
          ssnLastFourDigits: "",
        }}
        validationSchema={companyBankInfoSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, getFieldProps, submitForm }) => (
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Bank Account Number</Form.Label>
              <Form.Control
                isInvalid={touched.bankAccountNo && errors.bankAccountNo}
                type="text"
                name="bankAccountNo"
                placeholder="Enter Bank Account No"
                {...getFieldProps("bankAccountNo")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bankAccountNo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Routing Number</Form.Label>
              <Form.Control
                isInvalid={touched.routingNo && Boolean(errors.routingNo)}
                type="text"
                name="routingNo"
                placeholder="Enter your Routing number"
                {...getFieldProps("routingNo")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.routingNo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>SSN Last Four Digits</Form.Label>
              <Form.Control
                isInvalid={
                  touched.ssnLastFourDigits && Boolean(errors.ssnLastFourDigits)
                }
                type="text"
                name="ssnLastFourDigits"
                placeholder="Enter last four digits"
                {...getFieldProps("ssnLastFourDigits")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ssnLastFourDigits}
              </Form.Control.Feedback>
            </Form.Group>
            <CustomButton
              loading={loading}
              btnText="Proceed"
              onClick={submitForm}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Wrapper = (props) => (
  <Elements stripe={stripePromise}>
    <CompanyBankInformation {...props} />
  </Elements>
);

export default Wrapper;
