let salespersonRegistration = "salesPerson/registration",
  signin = "salesPerson/login",
  postVechicleImage = "upload/vehicle-image",
  saveDocument = "salesPerson/save/document",
  saveBankInfo = "bank/account",
  getMerchantListing = "sales-merchant",
  getProfileDetails = "salesPerson/profile",
  getDashboardStatistics = "salesPerson/dashboard",
  changePassword = "salesPerson/change/password",
  logout = "salesPerson/logout",
  forgotPassword = "salesPerson/forgot/password",
  resetPassword = "salesPerson/reset/password",
  getMerchantDetails = "admin/merchant";

export {
  salespersonRegistration,
  signin,
  postVechicleImage,
  saveDocument,
  saveBankInfo,
  getMerchantListing,
  getProfileDetails,
  getDashboardStatistics,
  changePassword,
  logout,
  forgotPassword,
  resetPassword,
  getMerchantDetails,
};
