import React, { useState } from "react";
import "./index.css";

import DARCAR_LOGO from "../../../../assets/images/darkcars_logo.png";
import { CustomButton, InputField } from "../../../../globalComponents";
import { postRequest } from "../../../../service/apiClient";
import { signin } from "../../../../service/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { ForgotPasswordModal } from "..";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState();

  const navigate = useNavigate();

  const handleSignin = async () => {
    if (!email || !password) return;
    setLoading(true);
    try {
      let params = {
        email,
        password,
      };
      let {
        data: { message, success, data },
      } = await postRequest(signin, params, false);
      setLoading(false);
      if (success) {
        localStorage.setItem("darkcarz", JSON.stringify(data));
        let { isSignupCompleted, signupStepCompleted } = data;
        if (isSignupCompleted) navigate("/dashboard");
        else {
          navigate("/createAccount", {
            state: { currentSignupSteps: signupStepCompleted + 1 },
          });
        }
      } else {
        toast.warn(message);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something went wrong");
      console.error(err);
    }
  };

  const handleNavigate = () => {
    navigate("/createAccount");
  };

  const handleforgotPassword = () => {
    setIsResetModalVisible(true);
  };

  return (
    <div className="loginform_container">
      <ForgotPasswordModal
        isModalVisible={isResetModalVisible}
        handleOk={() => setIsResetModalVisible(false)}
      />
      <div className="d-flex justify-content-center">
        <img src={DARCAR_LOGO} alt="" />
      </div>
      <div className="py-4 whiteText">
        <h4 className="medium" style={{ fontSize: 20 }}>
          Welcome back!
        </h4>
        <p className="medium" style={{ fontSize: 14 }}>
          Log In to your customer account
        </p>
      </div>
      <div className="login_fields_container">
        <InputField
          name="email"
          label="EMAIL"
          placeholder="Write your Email Adress"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br></br>
        <InputField
          name="password"
          label="PASSWORD"
          placeholder="Write your password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="text-center py-3">
          <span className="medium font_14 whiteText">Forgot Password?</span>
          &nbsp;
          <span
            className="medium yellowText font_14 is_pointer"
            onClick={handleforgotPassword}
          >
            Click here
          </span>
        </div>
      </div>
      <div>
        <CustomButton
          btnText="LOG IN"
          bgColor="#262626"
          onClick={handleSignin}
          loading={loading}
        />
        <CustomButton
          btnText="CREATE ACCOUNT"
          onClick={handleNavigate}
          style={{ marginTop: 10 }}
        />
      </div>
    </div>
  );
};

export default LoginForm;
