//async local storage

const asyncLocalStorage = {
  setItem: async function (key, value) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await null;
    return localStorage.getItem(key);
  },
  clear: async function () {
    await null;
    return localStorage.clear();
  },
};

export { asyncLocalStorage };
