import React from "react";
import { Table } from "react-bootstrap";

const AddressDetail = ({
  city = "N/A",
  postalCode = "N/A",
  state = "N/A",
  country = "N/A",
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>City</th>
          <th>Country</th>
          <th>Postal Code</th>
          <th>State</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{city}</td>
          <td>{country}</td>
          <td>{postalCode}</td>
          <td>{state}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AddressDetail;
