import React from "react";
import { Spinner, Button } from "react-bootstrap";

import "./index.css";

const CustomButton = ({
  btnText = "",
  loading = false,
  bgColor = "#CE993B",
  width = "100%",
  height = 39,
  color = "white",
  style = null,
  onClick = null,
}) => {
  return (
    <Button
      disabled={loading}
      onClick={onClick}
      className="custom_btn"
      variant="primary"
      style={{
        backgroundColor: bgColor,
        width,
        height,
        border: 0,
        color,
        ...style,
      }}
    >
      {loading && (
        <Spinner
          className="mr-2"
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <span> {btnText}</span>
    </Button>
  );
};

export default CustomButton;
