import React, { useRef, useState } from "react";
import { postFormData, postRequest } from "../../../../service/apiClient";
import { postVechicleImage, saveDocument } from "../../../../service/constants";
import "./index.css";
import UPLOAD_ICON from "../../../../assets/images/upload.png";
import { toast } from "react-toastify";
import { CustomButton, DeleteIcon } from "../../../../globalComponents";
import { Spinner } from "react-bootstrap";

const VerificationForm = ({ onSuccess }) => {
  const [frontImageUrl, setFrontImageUrl] = useState();
  const [backImageUrl, setBackImageUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const frontImageInputRef = useRef();
  const backImageInputRef = useRef();

  const handleChangeFile = (e, inputName) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    handleImageUpload(formData, inputName);
  };

  const handleImageUpload = async (body, inputName) => {
    try {
      setUploading(true);
      let {
        data: {
          data: { url },
          message,
          success,
        },
      } = await postFormData(postVechicleImage, body);
      setUploading(false);

      if (success) {
        inputName === "frontImageInput"
          ? setFrontImageUrl(url)
          : setBackImageUrl(url);
      } else {
        toast.error(message);
      }
    } catch (err) {
      setUploading(false);
      toast.error("Something went wrong");
      console.error(err);
    }
  };

  const handlePicturesSubmit = async () => {
    if (!frontImageUrl || !backImageUrl) return;
    setLoading(true);
    try {
      let params = {
        identityFrontImage: frontImageUrl,
        identityBackImage: backImageUrl,
      };
      let {
        data: { success, message },
      } = await postRequest(saveDocument, params);
      setLoading(false);
      if (success) {
        toast.success(message);
        onSuccess();
      } else {
        toast.warn(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const Placeholder = ({ text }) => (
    <div className="text-center p-3">
      <img src={UPLOAD_ICON} alt="" width="80" height="80" />
      <p className="greyText font_12 mt-3">{text}</p>
    </div>
  );

  const clearImage = (stateName) => {
    stateName === "frontImage" ? setFrontImageUrl() : setBackImageUrl();
  };

  return (
    <div>
      <span className="d-flex justify-content-center">
        <h2 className="form_heading">Verification </h2>
        {uploading && (
          <Spinner
            style={{ marginLeft: 10, marginTop: 5 }}
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </span>

      <p className="greyText font_14">
        Please make sure to upload the clear copies
      </p>
      <div className="d-flex justify-content-around my-5">
        <div
          className="upload_div"
          onClick={() => !frontImageUrl && frontImageInputRef.current.click()}
        >
          <input
            type="file"
            ref={frontImageInputRef}
            style={{ display: "none" }}
            onChange={(e) => handleChangeFile(e, "frontImageInput")}
          />
          {frontImageUrl && (
            <div style={{ position: "absolute", top: -20, left: -20 }}>
              <DeleteIcon onClick={() => clearImage("frontImage")} />
            </div>
          )}
          {frontImageUrl ? (
            <img
              src={frontImageUrl}
              alt=""
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
            />
          ) : (
            <Placeholder text="Upload id card front image (Required)" />
          )}
        </div>
        <div
          className="upload_div"
          onClick={() => !backImageUrl && backImageInputRef.current.click()}
        >
          <input
            type="file"
            ref={backImageInputRef}
            style={{ display: "none" }}
            onChange={(e) => handleChangeFile(e, "backImageInput")}
          />
          {backImageUrl && (
            <div style={{ position: "absolute", top: -20, left: -20 }}>
              <DeleteIcon onClick={() => clearImage("backImage")} />
            </div>
          )}
          {backImageUrl ? (
            <img
              src={backImageUrl}
              alt=""
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
            />
          ) : (
            <Placeholder text="Upload id card back image (Required)" />
          )}
        </div>
      </div>
      <CustomButton
        btnText="Proceed"
        loading={uploading || loading}
        onClick={handlePicturesSubmit}
      />
    </div>
  );
};

export default VerificationForm;
