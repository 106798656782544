import { LogoutOutlined, ProfileOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HAMBURGER from "../../../../assets/images/icon_menu_black.svg";
import PROFILE_ICON from "../../../../assets/images/user.png";
import { getRequest } from "../../../../service/apiClient";
import { logout } from "../../../../service/constants";
import DARCAR_LOGO from "../../../../assets/images/logo_dark.png";

import "./index.css";

const menu = (handleLogout) => (
  <Menu>
    <Menu.Item key="0">
      <NavLink to="/manageProfile">
        <div className="d-flex align-items-center">
          <span className="logout_">Profile</span> <ProfileOutlined />{" "}
        </div>
      </NavLink>
    </Menu.Item>
    <Menu.Divider />

    <Menu.Item onClick={handleLogout} key="1">
      <div className="d-flex align-items-center">
        <span className="logout_">Logout</span> <LogoutOutlined />
      </div>
    </Menu.Item>
  </Menu>
);

const Header = ({ onSidebarToggle }) => {
  const [username, setUsername] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let darkcarz = JSON.parse(localStorage.getItem("darkcarz"));
    if (darkcarz) {
      setUsername(darkcarz.firstName);
    }
  }, []);

  const handleLogout = async () => {
    try {
      let {
        data: { message, success },
      } = await getRequest(logout);
      if (success) {
        localStorage.removeItem("darkcarz");
        navigate("/login");
      } else {
        toast.warn(message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="header_">
      <div className="d-flex align-items-center">
        <img
          src={HAMBURGER}
          onClick={onSidebarToggle}
          alt=""
          style={{ marginRight: 10, cursor: "pointer" }}
        />
        <h1 className="logo_text" onClick={() => navigate("/dashboard")}>
          Dark Carz
        </h1>
      </div>

      <div className="d-flex align-items-center">
        <p className="username_">{username}</p>
        <Dropdown overlay={menu(handleLogout)} trigger={["click"]}>
          <img
            src={PROFILE_ICON}
            alt=""
            width="30"
            height="30"
            className="ant-dropdown-link"
            style={{ cursor: "pointer" }}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
