import React from "react";

const PageHeading = ({ text }) => {
  return (
    <div style={{ width: "fit-content" }}>
      <h2 className="subcontent_heading">{text}</h2>
    </div>
  );
};

export default PageHeading;
