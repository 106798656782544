import { Modal } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../globalComponents";
import { postRequest } from "../../../../service/apiClient";
import { forgotPassword } from "../../../../service/constants";
import { resetPasswordSchema } from "../../../../utils/validationSchemas";
import "./index.css";

const ForgotPasswordModal = ({ isModalVisible, handleOk }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (values, formik) => {
    try {
      setIsLoading(true);
      let {
        data: { message, success },
      } = await postRequest(forgotPassword, { ...values });
      setIsLoading(false);
      if (success) {
        toast.success(message);
        handleOk();
      } else {
        toast.warn(message);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <Formik
      onSubmit={handleResetPassword}
      initialValues={{
        email: "",
      }}
      validationSchema={resetPasswordSchema}
    >
      {({ errors, touched, getFieldProps, submitForm }) => (
        <Modal
          onCancel={handleOk}
          className="forgot_password"
          title="Forgot Password"
          visible={isModalVisible}
          onOk={handleOk}
          footer={[
            <CustomButton
              loading={isLoading}
              btnText="Submit"
              onClick={submitForm}
            />,
          ]}
        >
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              isInvalid={touched.email && Boolean(errors.email)}
              type="email"
              name="email"
              placeholder="Enter your Email"
              {...getFieldProps("email")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal>
      )}
    </Formik>
  );
};

export default ForgotPasswordModal;
