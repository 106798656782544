import { Fragment } from "react";
import { Navigate } from "react-router";
import { NoPageFound } from "../globalComponents";
import {
  LoginPage,
  SignupPage,
  DashboardPage,
  ManageProfile,
  ResetPasswordResponsePage,
} from "../pages";
import {
  HomePage,
  MerchantListing,
  MerchantDetail,
} from "../pages/dashboardPage/subPages";

const getRoutes = (isLoggedIn) => [
  {
    element: <NoPageFound />,
    path: "*",
  },
  {
    element: <Navigate replace to="/dashboard" />,
    path: "/",
  },
  {
    element: <ResetPasswordResponsePage />,
    path: "/password/reset",
  },

  {
    element: isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />,
    path: "/login",
  },
  {
    element: <SignupPage />,
    path: "/createAccount",
  },
  {
    element: <ManageProfile />,
    path: "/manageProfile",
  },
  {
    element: isLoggedIn ? (
      <Fragment>
        <DashboardPage />
      </Fragment>
    ) : (
      <Navigate to="/login" />
    ),
    path: "dashboard",
    children: [
      { path: "", element: <HomePage /> },
      {
        element: <MerchantListing />,
        path: "merchants",
      },
      { element: <MerchantDetail />, path: "merchants/detail" },
    ],
  },
];
export default getRoutes;
