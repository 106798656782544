import React, { useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { useLocation } from "react-router-dom";
import {
  CompanyRegistrationForm,
  VerificationForm,
  CompanyBankInfo,
} from "./components";
import "./index.css";
import DARCAR_LOGO from "../../assets/images/darkcars_logo.png";

const SignupPage = () => {
  const location = useLocation();
  const { currentSignupSteps = 0 } = location?.state || {};
  const [activeStep, setActiveStep] = useState(currentSignupSteps);
  const components = [
    <CompanyRegistrationForm
      onSuccess={() => setActiveStep((prevValue) => prevValue + 1)}
    />,
    <VerificationForm
      onSuccess={() => setActiveStep((prevValue) => prevValue + 1)}
    />,
    <CompanyBankInfo />,
  ];

  return (
    <div className="signup_page_container">
      <div className="sticky-top">
        <div className="signup_header">
          <img src={DARCAR_LOGO} alt="" width="auto" height="50" />
        </div>
        <Stepper
          style={{ backgroundColor: "white" }}
          styleConfig={{
            completedBgColor: "#CE993B",
            activeBgColor: "#CE993B",
            inactiveBgColor: "#262626",
          }}
          connectorStyleConfig={{ size: 3, completedColor: "#CE993B" }}
          activeStep={activeStep}
        >
          <Step onClick={() => setActiveStep(0)} label="Registration Details" />
          <Step onClick={() => setActiveStep(1)} label="Verification" />
          <Step onClick={() => setActiveStep(2)} label="Bank Info" />
        </Stepper>
      </div>
      <div className="signup_forms_container">{components[activeStep]}</div>
    </div>
  );
};

export default SignupPage;
