import React, { useState } from "react";
import { Outlet } from "react-router";
import { Header, Sidebar } from "./components";
import "./index.css";

const DashboardPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="dashboard_page">
      <Header onSidebarToggle={() => setIsSidebarOpen((prevVal) => !prevVal)} />
      <div className="d-flex">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
        />
        <div className="content_container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
