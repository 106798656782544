import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import { BankOutlined } from "@ant-design/icons";
import { getRequest } from "../../../../service/apiClient";
import { toast } from "react-toastify";
import { saveBankInfo as getBankInfo } from "../../../../service/constants";
import { Row, Col } from "react-bootstrap";
import { Loader } from "../../../../globalComponents";

const BankInfoDetail = () => {
  const [profileDetail, setProfileDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        let {
          data: { success, message, data },
        } = await getRequest(getBankInfo);
        setLoading(false);
        if (success) {
          setProfileDetail(data);
        } else {
          toast.warn(message);
        }
      } catch (err) {
        setLoading(false);
        toast.error(err);
      }
    };
    init();
  }, []);

  return (
    <div className="bankinfo_container">
      <Avatar
        style={{ alignSelf: "center" }}
        icon={<BankOutlined />}
        size={100}
      />
      {loading ? (
        <Loader />
      ) : (
        <Row className="mt-5 ">
          <Col xs={12} sm={8}>
            <p className="profile_label">Account Number</p>
            <h4 className="profile_value">{profileDetail?.last4 ? `****${profileDetail?.last4}`  : "N/A"}</h4>
          </Col>
          <Col xs={12} sm={4}>
            <p className="profile_label">Routing Number</p>
            <h4 className="profile_value">
              {profileDetail?.routingNum || "N/A"}
            </h4>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BankInfoDetail;
