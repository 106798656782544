import React from "react";
import "./index.css";
import { Col, Container, Row } from "react-bootstrap";
import { LoginForm } from "./components";
import DDLOGO from "../../assets/images/logoGrey.png";
import DARKCAR from "../../assets/images/darkcar.png";
import { motion } from "framer-motion";

const LoginPage = () => {
  return (
    <div className="login_container">
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        src={DDLOGO}
        className="dd_logo"
        alt=""
      />
      <Container className="py-5">
        <Row className="gy-5">
          <Col
            xs={12}
            sm={7}
            className="d-flex flex-column justify-content-center"
          >
            <motion.h1
              initial={{ scaleY: 0, opacity: 0 }}
              animate={{
                scaleY: 1,
                opacity: 1,
                transition: { delay: 0.5, easings: "anticipate" },
              }}
              className="welcometext"
            >
              Welcome to<mark>DarkCarz</mark>
            </motion.h1>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.5, delay: 0.5 },
              }}
              src={DARKCAR}
              alt=""
              width="90%"
              height="auto"
            />
          </Col>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5, delay: 1 } }}
            className="col-xs-12 col-sm-5"
          >
            <LoginForm />
          </motion.div>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
