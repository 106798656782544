import { Result } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { CustomButton } from "..";

const NoPageFound = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <CustomButton
            onClick={() => navigate("/dashboard")}
            btnText="Back Home"
            width="200px"
          />
        }
      />
    </div>
  );
};

export default NoPageFound;
