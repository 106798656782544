import { DatePicker, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import ICON_JOB from "../../../../assets/images/icon_jobs.png";
import ICON_MERCHANTS from "../../../../assets/images/icon_merchants.png";
import ICON_MONEY from "../../../../assets/images/icon_money.png";
import { Loader } from "../../../../globalComponents";
import PageHeading from "../../../../globalComponents/pageHeading";
import { postRequest } from "../../../../service/apiClient";
import { getDashboardStatistics } from "../../../../service/constants";
import "./index.css";


const { RangePicker } = DatePicker;

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState(undefined);
  const [startEndDate, setStartEndDate] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        let params = {
          startDate: startEndDate[0] ? startEndDate[0].unix() : 0,
          endDate: startEndDate[1] ? startEndDate[1].unix() : 0,
        };
        let {
          data: { success, message, data },
        } = await postRequest(getDashboardStatistics, params);
        setLoading(false);
        if (!success) {
          toast.warn(message);
        } else {
          setStatistics(data);
        }
      } catch (err) {
        toast.error("Something went wrong");
        setLoading(false);
      }
    };
    init();
  }, [startEndDate]);

  const onChange = (value, dateString) => {
    setStartEndDate(value ? [...value] : []);
  };

  return (
    <div className="dashboard_container">
      <PageHeading text="Dashboard" />
      <div className="mt-3 d-flex flex-row-reverse">
        <RangePicker allowClear value={startEndDate} onChange={onChange} />
      </div>

      {loading ? (
        <Loader />
      ) : typeof statistics === "object" &&
        Object.keys(statistics).length > 0 ? (
        <Row className="gy-3 mt-3">
          {/* <DashboardCard
            label="Total Merchants"
            value={statistics?.totalMerchants}
            icon={ICON_MERCHANTS}
            /> */}
          <DashboardCard
            label="Total Jobs"
            value={statistics?.totalJobs}
            icon={ICON_JOB}
          />
          <DashboardCard
            label="Amount Earned"
            value={statistics?.totalAmountEarned}
            icon={ICON_MONEY}
          />
        </Row>
      ) : (
        <Empty />
      )}
    </div>
  );
};

const DashboardCard = ({ label, value, icon }) => (
  <Col xs={12} sm={6} md={4} lg={3}>
    <div className="dashboard_card">
      <p className="dashboard_card_label greyText">{label}</p>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="dashboard_card_value">{value}</h4>
        <img src={icon} alt="" height="20"/>
      </div>
    </div>
  </Col>
);

export default HomePage;
