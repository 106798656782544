import * as Yup from "yup";

//regex

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// create company schema

const companySchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("First name is Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("last name is Required"),
  phoneNumber: Yup.string()
    .required("phone number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "to short")
    .max(10, "to long"),
  password: Yup.string().min(2, "too short").required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please retype password"),
  dob: Yup.date().required("Date of birth is required"),
  address: Yup.string().required("Address is required"),
});

// compnay bank information

const companyBankInfoSchema = Yup.object().shape({
  bankAccountNo: Yup.string().required("Bank Account no is required"),
  routingNo: Yup.string().required("Routing Number is Required"),
  ssnLastFourDigits: Yup.string()
    .test(
      "len",
      "Must be exactly 4 characters",
      (val) => val && val.length === 4
    )
    .required("SSN four digit is Required"),
});

// change password

const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(2, "too short")
    .required("Current password is required"),
  newPassword: Yup.string()
    .min(8, "too short")
    .required("New password is required"),
});

// reset password #1

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

// reset password #2

const newPasswordSchema = Yup.object().shape({
  password: Yup.string().min(2, "too short").required("Password is required"),
  newPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please retype password"),
});

export {
  companySchema,
  companyBankInfoSchema,
  changePasswordSchema,
  resetPasswordSchema,
  newPasswordSchema,
};
