import React from "react";
import "./index.css";
import { Tabs } from "antd";

import DARCAR_LOGO from "../../assets/images/darkcars_logo.png";
import { BankInfoDetail, ProfileDetails } from "./components";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";

const { TabPane } = Tabs;

const ManageProfile = () => {
  const navigate = useNavigate();

  return (
    <div className="manage_profile_container">
      <div className="d-flex align-items-center py-1 bg-black px-5 justify-content-between">
        <HomeOutlined
          onClick={() => navigate("/dashboard")}
          style={{ color: "white", fontSize: 32, alignSelf: "center" }}
        />
        <img src={DARCAR_LOGO} alt="" width="auto" height="50" />
      </div>
      <Tabs defaultActiveKey="1" centered className="mt-2">
        <TabPane tab="Profile Details" key="1">
          <div className="tab_container">
            <ProfileDetails />
          </div>
        </TabPane>
        <TabPane tab="Bank Information" key="2">
          <div className="tab_container">
            <BankInfoDetail />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ManageProfile;
