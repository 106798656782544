// dashboard icons

const dashboardSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.002"
    height="17.002"
    viewBox="0 0 17.002 17.002"
  >
    <g id="dashboard_black" transform="translate(0.5 0.5)">
      <path
        id="Path_94"
        data-name="Path 94"
        d="M5.829,0H1.1A1.1,1.1,0,0,0,0,1.1V3.939a1.1,1.1,0,0,0,1.1,1.1H5.829a1.1,1.1,0,0,0,1.1-1.1V1.1A1.1,1.1,0,0,0,5.829,0Zm0,0"
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_95"
        data-name="Path 95"
        d="M5.829,213.332H1.1a1.1,1.1,0,0,0-1.1,1.1v6.617a1.1,1.1,0,0,0,1.1,1.1H5.829a1.1,1.1,0,0,0,1.1-1.1v-6.617A1.1,1.1,0,0,0,5.829,213.332Zm0,0"
        transform="translate(0 -206.153)"
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_96"
        data-name="Path 96"
        d="M283.162,341.332h-4.727a1.1,1.1,0,0,0-1.1,1.1v2.836a1.1,1.1,0,0,0,1.1,1.1h4.727a1.1,1.1,0,0,0,1.1-1.1v-2.836A1.1,1.1,0,0,0,283.162,341.332Zm0,0"
        transform="translate(-268.262 -330.372)"
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_97"
        data-name="Path 97"
        d="M283.162,0h-4.727a1.1,1.1,0,0,0-1.1,1.1V7.72a1.1,1.1,0,0,0,1.1,1.1h4.727a1.1,1.1,0,0,0,1.1-1.1V1.1A1.1,1.1,0,0,0,283.162,0Zm0,0"
        transform="translate(-268.262)"
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </g>
  </svg>
);

const merchantSvg =  (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.57"
    height="20.517"
    viewBox="0 0 31.57 20.517"
  >
    <g id="icon_merchants" transform="translate(-2.215 -7.748)">
      <path
        id="Path_53346"
        data-name="Path 53346"
        d="M24.546,23.534c-.788-.281-2.074-.3-2.644-.513a3.735,3.735,0,0,1-1.223-.57,7.56,7.56,0,0,1-.2-2,3.237,3.237,0,0,0,.7-.984,10.94,10.94,0,0,0,.337-1.934s.464.2.647-.731c.155-.8.45-1.223.373-1.814s-.408-.45-.408-.45a5.265,5.265,0,0,0,.408-2.63,4.208,4.208,0,0,0-4.542-4.155A4.222,4.222,0,0,0,13.437,11.9a5.343,5.343,0,0,0,.4,2.63s-.33-.141-.408.45S13.641,16,13.8,16.8c.183.935.647.731.647.731a11.046,11.046,0,0,0,.337,1.934,3.237,3.237,0,0,0,.7.984,7.56,7.56,0,0,1-.2,2,3.6,3.6,0,0,1-1.223.563c-.562.218-1.849.246-2.644.527a4.962,4.962,0,0,0-3.22,4.725H27.759A4.952,4.952,0,0,0,24.546,23.534Z"
        fill="#ce993b"
      />
      <path
        id="Path_53347"
        data-name="Path 53347"
        d="M9.844,20.109a4.773,4.773,0,0,0,2.348-.654c-1.09-1.652-.5-3.579-.724-5.379a2.966,2.966,0,0,0-3.284-2.869H8.156A2.976,2.976,0,0,0,4.9,14.077c-.225,1.793.4,3.938-.717,5.379a4.417,4.417,0,0,0,2.355.584h0a3.561,3.561,0,0,1-.07,1.181,1.775,1.775,0,0,1-.844.387,16.314,16.314,0,0,0-1.821.506,2.96,2.96,0,0,0-1.589,2.616H7.861a5.051,5.051,0,0,1,2.25-2.1,4.722,4.722,0,0,1,1.737-.366s.267-.422-.612-.584a5.994,5.994,0,0,1-1.35-.471C9.752,21.059,9.844,20.109,9.844,20.109Z"
        fill="#ce993b"
      />
      <path
        id="Path_53348"
        data-name="Path 53348"
        d="M26.156,20.109a4.773,4.773,0,0,1-2.348-.654c1.09-1.652.5-3.579.724-5.379a2.966,2.966,0,0,1,3.284-2.869h.028A2.976,2.976,0,0,1,31.1,14.077c.225,1.793-.4,3.938.717,5.379a4.417,4.417,0,0,1-2.355.584h0a3.561,3.561,0,0,0,.07,1.181,1.775,1.775,0,0,0,.844.387,16.314,16.314,0,0,1,1.821.506,2.96,2.96,0,0,1,1.589,2.616H28.139a5.051,5.051,0,0,0-2.25-2.1,4.722,4.722,0,0,0-1.737-.366s-.267-.422.612-.584a5.994,5.994,0,0,0,1.35-.471C26.248,21.059,26.156,20.109,26.156,20.109Z"
        fill="#ce993b"
      />
    </g>
  </svg>
);

export { dashboardSvg, merchantSvg };
