import React from "react";
import { Spin } from "antd";

const Loader = () => {
  return (
    <div className="d-flex flex-1 justify-content-center align-items-center py-5">
      <Spin />
    </div>
  );
};

export default Loader;
