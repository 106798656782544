import React from "react";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";

import "./index.css";
import DARCAR_LOGO from "../../assets/images/darkcarz_black_logo.png";
import { Form } from "react-bootstrap";
import { newPasswordSchema } from "../../utils/validationSchemas";
import { Formik } from "formik";
import { CustomButton } from "../../globalComponents";
import { postRequest } from "../../service/apiClient";
import { resetPassword } from "../../service/constants";
import { toast } from "react-toastify";

const ResetPasswordResponsePage = () => {
  const location = useLocation();
  let status = queryString.parse(location.search)?.status;
  let token = queryString.parse(location.search)?.token;
  const navigate = useNavigate();

  const handleFormSubmit = async (values, formik) => {
    try {
      let {
        data: { message, success },
      } = await postRequest(resetPassword, {
        password: values.password,
        token,
      });
      if (success) {
        toast.success(message);
        navigate("/login", { replace: true });
      } else {
        toast.warn(message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const getViewByStatus = () => {
    if (status === "1") {
      return (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={{
            password: "",
            newPassword: "",
          }}
          validationSchema={newPasswordSchema}
        >
          {({ errors, touched, getFieldProps, submitForm }) => (
            <>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  isInvalid={touched.password && Boolean(errors.password)}
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  {...getFieldProps("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  isInvalid={touched.newPassword && Boolean(errors.newPassword)}
                  type="password"
                  name="newPassword"
                  placeholder="Enter your new password"
                  {...getFieldProps("newPassword")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <CustomButton
                btnText="Reset"
                width="200px"
                onClick={submitForm}
                style={{ marginTop: 20 }}
              />
            </>
          )}
        </Formik>
      );
    } else if (status === "2") {
      return <p>Your link expired</p>;
    } else if (status === "3") {
      return <p>This link is expired</p>;
    }
  };

  return (
    <div className="reset_response_container">
      <img src={DARCAR_LOGO} alt="" />
      {status && getViewByStatus()}
    </div>
  );
};

export default ResetPasswordResponsePage;
