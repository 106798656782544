import React from "react";
import "./index.css";

const InputField = ({
  name = "",
  placeholder = "",
  label = "",
  type = "text",
  onChange = null
}) => {
  return (
    <div className="input_field">
      <label>{label}</label>
      <input onChange={onChange} name={name} placeholder={placeholder} type={type} />
    </div>
  );
};

export default InputField;
