import React, { useEffect, useState } from "react";
import { PageHeading } from "../../../../globalComponents";
import { getRequest } from "../../../../service/apiClient";
import { getMerchantListing } from "../../../../service/constants";
import { Table, Input, Tag } from "antd";

import "./index.css";
import { toast } from "react-toastify";
import { useDebounce } from "../../../../hooks";
import { useNavigate } from "react-router";

const MerchantListing = () => {
  const [merchantListing, setMerchantListing] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(searchQuery, 500);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        let darkcarz = JSON.parse(localStorage.getItem("darkcarz"));
        if (!darkcarz) return;
        let id = darkcarz._id;
        let {
          data: {
            data: { totalMerchantsCount, merchantsFound },
            success,
            message,
          },
        } = await getRequest(
          `${getMerchantListing}/${id}?limit=10&&offset=${offset}&&searchFilter=${debouncedSearchTerm}`
        );
        setIsLoading(false);
        if (success) {
          setMerchantListing(merchantsFound);
          settotalCount(totalMerchantsCount);
        } else {
          toast.warn(message);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    };
    init();
  }, [offset, debouncedSearchTerm]);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Account Status",
      key: "accountApproval",
      render: ({ isAccountApproved, isAccountRejected }) => (
        <Tag color={getTagColor(isAccountApproved, isAccountRejected)}>
          {isAccountApproved
            ? "Accepted"
            : !isAccountApproved && !isAccountRejected
            ? "Pending"
            : "Rejected"}
        </Tag>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Status",
      key: "blocking",
      render: ({ blocking }) => (
        <Tag color={blocking ? "red" : "green"}>
          {blocking ? "Blocked" : "Active"}
        </Tag>
      ),
    },
  ];

  const getTagColor = (isAccountApproved, isAccountRejected) => {
    if (isAccountApproved) {
      return "green";
    } else if (!isAccountApproved && !isAccountRejected) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const handleTableChange = (pagination) => {
    let offset = pagination.current * pagination.pageSize - pagination.pageSize;
    setOffset(offset);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="merchant_listing_container">
      <div className="d-flex justify-content-between">
        <PageHeading text="Merchants" />
        <Input
          placeholder="Search Here"
          style={{ maxWidth: 300 }}
          onChange={handleInputChange}
          value={searchQuery}
        />
      </div>
      <Table
        loading={isLoading}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              navigate("/dashboard/merchants/detail", {
                state: { recordId: record?._id },
              });
            },
          };
        }}
        size="small"
        pagination={{
          total: totalCount,
        }}
        rowClassName={'antd_table_row'}
        onChange={handleTableChange}
        className="mt-4"
        columns={columns}
        dataSource={merchantListing}
      />
    </div>
  );
};

export default MerchantListing;
