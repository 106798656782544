import { motion } from "framer-motion";
import React from "react";
import { NavLink } from "react-router-dom";
import { dashboardSvg, merchantSvg } from "../../../../svgs";
import "./index.css";

const Sidebar = ({ isSidebarOpen }) => {
  const closedLinkStyle = {
    paddingLeft: 20,
    paddingRight: 20,
  };

  return (
    <motion.div
      animate={{ width: isSidebarOpen ? 210 : 60 }}
      className="sidebar sticky-top"
    >
      <div className="sidebar_links_container">
        {getNavLinks.map(({ title, to, icon }, index) => (
          <NavLink
            style={isSidebarOpen ?  null : closedLinkStyle}
            key={index}
            end
            to={to}
          >
            {icon}
            <motion.span
              animate={{
                opacity: isSidebarOpen ? 1 : 0,
                position: isSidebarOpen ? "static" : "absolute",
              }}
            >
              {title}
            </motion.span>
          </NavLink>
        ))}
      </div>
    </motion.div>
  );
};

const getNavLinks = [
  {
    icon: dashboardSvg,
    title: "Dashboard",
    to: "/dashboard",
  },
  // {
  //   icon: merchantSvg,
  //   title: "Merchants",
  //   to: "/dashboard/merchants",
  // },
];

export default Sidebar;
