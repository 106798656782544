import { Avatar, message } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getRequest } from "../../../../service/apiClient";
import { getProfileDetails } from "../../../../service/constants";
import { UserOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import { Loader } from "../../../../globalComponents";

import "./index.css";
import { ChangePasswordModal } from "./components";

const ProfileDetail = () => {
  const [profileDetail, setProfileDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        let {
          data: { success, message, data },
        } = await getRequest(getProfileDetails);
        setLoading(false);
        if (success) {
          setProfileDetail(data);
        } else {
          toast.warn(message);
        }
      } catch (err) {
        setLoading(false);
        toast.error(err);
      }
    };
    init();
  }, []);

  const hidePasswordModal = () => setIsChangePasswordModal(false);

  const copyToClipboard = () => {
    const text = profileDetail?.referralCode;
    if (!text) return;
    navigator.clipboard.writeText(text);
    message.success("Copied to Clipboard");
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="profile_detail_container">
      <ChangePasswordModal
        isModalVisible={isChangePasswordModal}
        handleOk={hidePasswordModal}
        handleCancel={hidePasswordModal}
      />
      <Avatar
        style={{ alignSelf: "center" }}
        icon={<UserOutlined />}
        size={100}
      />
      <Row className="mt-5 ">
        <Col xs={12} sm={8}>
          <p className="profile_label">Email</p>
          <h4 className="profile_value">{profileDetail?.email}</h4>
        </Col>
        <Col xs={12} sm={4}>
          <p className="profile_label">Phone Number</p>
          <h4 className="profile_value">{profileDetail?.phoneNumber}</h4>
        </Col>
        <Col xs={12} sm={8}>
          <p className="profile_label">Name</p>
          <h4 className="profile_value">
            {profileDetail?.firstName + " " + profileDetail?.lastName}
          </h4>
        </Col>
        <Col xs={12} sm={4}>
          <div className="d-flex">
            <p className="profile_label" style={{ marginRight: 10 }}>
              Password
            </p>
            <EditOutlined
              onClick={() => setIsChangePasswordModal(true)}
              style={{ cursor: "pointer", color: "#ce993b" }}
            />
          </div>
          <h4 className="profile_value">****</h4>
        </Col>
        <Col xs={12} sm={8}>
          <div className="d-flex">
            <p className="profile_label" style={{ marginRight: 10 }}>
              Referral Code
            </p>
            <CopyOutlined
              style={{ cursor: "pointer", color: "#ce993b" }}
              onClick={copyToClipboard}
            />
          </div>
          <h4 className="profile_value">{profileDetail?.referralCode}</h4>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileDetail;
