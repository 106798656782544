import React, { useRef, useState } from "react";
import { Modal } from "antd";
import "./index.css";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { changePasswordSchema } from "../../../../../../utils/validationSchemas";
import { CustomButton } from "../../../../../../globalComponents";
import { postRequest } from "../../../../../../service/apiClient";
import { changePassword } from "../../../../../../service/constants";
import { toast } from "react-toastify";

const ChangePasswordModal = ({ isModalVisible, handleOk, handleCancel }) => {
  const submitBtnRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (values) => {
    setIsLoading(true);
    let params = {
      password: values.currentPassword,
      newPassword: values.newPassword,
    };

    try {
      let {
        data: { success, message },
      } = await postRequest(changePassword, params);
      setIsLoading(false);
      if (success) {
        toast.success(message);
        handleOk();
      } else {
        toast.warn(message);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <Modal
      title="Change Password"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <CustomButton
          loading={isLoading}
          btnText="Save"
          onClick={() => submitBtnRef.current.click()}
        />,
      ]}
    >
      <Formik
        onSubmit={submitForm}
        initialValues={{
          currentPassword: "",
          newPassword: "",
        }}
        validationSchema={changePasswordSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          getFieldProps,
          submitForm,
        }) => (
          <div className="change_password_container">
            <Form.Group>
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                isInvalid={
                  touched.currentPassword && Boolean(errors.currentPassword)
                }
                type="password"
                name="currentPassword"
                placeholder="Enter your Current Password"
                {...getFieldProps("currentPassword")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.currentPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                isInvalid={touched.newPassword && Boolean(errors.newPassword)}
                type="password"
                name="newPassword"
                placeholder="Enter your New Password"
                {...getFieldProps("newPassword")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <button
              style={{ display: "none" }}
              ref={submitBtnRef}
              onClick={submitForm}
            ></button>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePasswordModal;
