import { useRoutes } from "react-router-dom";
import getRoutes from "./constants";

const RouterConfig = () => {
  const getLoginStatus = () => {
    let darkcarz =
      localStorage.getItem("darkcarz") &&
      JSON.parse(localStorage.getItem("darkcarz"));
    if (darkcarz) {
      let { isSignupCompleted } = darkcarz;
      return isSignupCompleted;
    } else {
      return false;
    }
  };

  return useRoutes(getRoutes(getLoginStatus()));
};

export default RouterConfig;
