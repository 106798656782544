import React, { useEffect, useState } from "react";
import { Loader, PageHeading } from "../../../../globalComponents";
import "./index.css";
import { Descriptions, Badge } from "antd";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest } from "../../../../service/apiClient";
import { getMerchantDetails } from "../../../../service/constants";
import { useLocation } from "react-router";

import PASTJOBSRC from "../../../../assets/images/past-jobs-icon.png";
import SCHEDULEJOBSRC from "../../../../assets/images/schedule-jobs-icon.png";
import TOTALJOBSSRC from "../../../../assets/images/total-jobs-icon.png";

const MerchantDetail = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [merchantDetail, setMerchantDetail] = useState({});

  let {
    state: { recordId },
  } = location;

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        let {
          data: {
            data: { merchantDetails, ...rest },
            message,
            success,
          },
        } = await getRequest(`${getMerchantDetails}/${recordId}`);
        setIsLoading(false);

        if (success) {
          setMerchantDetail({ ...rest, ...merchantDetails });
        }

        if (!success) {
          toast.warn(message);
        }
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    };
    init();
  }, []);

  useEffect(() => {
    console.log("merchantDetail", merchantDetail);
  }, [merchantDetail]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="merchant_detaill_container">
      <PageHeading text="Merchants Details" />
      <Row className="mt-4 gy-3">
        <Col xs={12}>
          <Row className="gy-3">
            <Col>
              <MerchantStatCard
                imgSrc={PASTJOBSRC}
                number={merchantDetail?.pendingJobsCount}
                title="Pending Jobs"
              />
            </Col>
            <Col>
              <MerchantStatCard
                imgSrc={SCHEDULEJOBSRC}
                number={merchantDetail?.completedJobsCount}
                title="Completed Jobs"
              />
            </Col>
            <Col>
              <MerchantStatCard
                imgSrc={TOTALJOBSSRC}
                number={merchantDetail?.totalDriversCount}
                title="Drivers"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Descriptions bordered style={{ backgroundColor: "white" }}>
            <Descriptions.Item label="Email">
              {merchantDetail?.email}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {merchantDetail?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Company Name">
              {merchantDetail?.companyName}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  );
};

const MerchantStatCard = ({ imgSrc, title, number }) => (
  <div className="merchant_stat_card">
    <h5>{title}</h5>
    <div className="d-flex justify-content-between">
      <p>{number}</p>
      <img src={imgSrc} alt="" />
    </div>
  </div>
);

export default MerchantDetail;
