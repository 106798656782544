import "./App.css";
import RouterConfig from "./navigation";
import "./globalStyle/globalStyle.css";
import { NotificationContainer } from "./globalComponents";

function App() {
  return (
    <div className="app_container">
      <NotificationContainer />
      <RouterConfig />
    </div>
  );
}

export default App;
