import { Formik } from "formik";
import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";
import { toast } from "react-toastify";
import { AddressDetailTable } from "..";
import { CustomButton } from "../../../../globalComponents";
import { postRequest } from "../../../../service/apiClient";
import { salespersonRegistration } from "../../../../service/constants";
import { companySchema } from "../../../../utils/validationSchemas";
import "./index.css";

const CompanyRegistrationForm = ({ onSuccess }) => {
  const [addressComponent, setAddressComponent] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { ref: addressRef } = usePlacesWidget({
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: ["us"] },
    },
    apiKey: "AIzaSyA_DoKjsY1wZw8Ohjf-BygMkGU8v9ZL9Vc",
    onPlaceSelected: (place) => {
      handleAddressChange(place);
    },
  });

  const submitForm = async (values, s) => {
    setLoading(true);
    let unixDob = new Date(values.dob).getTime() / 1000;
    let params = {
      ...values,
      ...addressComponent,
      phoneNumberPrefix: "+1",
      dob: unixDob,
    };
    try {
      let {
        data: { success, message },
      } = await postRequest(salespersonRegistration, params);
      if (success) {
        toast.success(message);
        onSuccess();
      } else {
        toast.warn(message);
      }
      setLoading(false);
    } catch (err) {
      toast.error("something went wrong");
      setLoading(false);
      console.error(err);
    }
  };

  const handleAddressChange = (address) => {
    let addresses = address.address_components;
    let addressDetail = undefined;
    addressDetail = { lineAddress: address.formatted_address };
    addresses.forEach((element, index) => {
      element.types.forEach((type, i) => {
        if (type === "locality") {
          addressDetail = {
            ...addressDetail,
            city: element.long_name,
          };
        } else if (type === "postal_code") {
          addressDetail = {
            ...addressDetail,
            postalCode: element.long_name,
          };
        } else if (type === "country") {
          addressDetail = {
            ...addressDetail,
            country: element.long_name,
          };
        } else if (type === "administrative_area_level_1") {
          addressDetail = {
            ...addressDetail,
            state: element.long_name,
          };
        }
      });
    });
    addressDetail && setAddressComponent({ ...addressDetail });
  };

  return (
    <div className="registration_form_container">
      <h2 className="form_heading">Company Registration</h2>
      <Formik
        onSubmit={submitForm}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
          address: "",
          dob: "",
        }}
        validationSchema={companySchema}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          getFieldProps,
          submitForm,
        }) => (
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                isInvalid={touched.firstName && Boolean(errors.firstName)}
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                {...getFieldProps("firstName")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                isInvalid={touched.lastName && Boolean(errors.lastName)}
                type="text"
                name="lastName"
                placeholder="Enter your last name"
                {...getFieldProps("lastName")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                isInvalid={touched.email && Boolean(errors.email)}
                type="email"
                name="email"
                placeholder="Enter your Email"
                {...getFieldProps("email")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <InputGroup>
                <InputGroup.Text>+1</InputGroup.Text>
                <Form.Control
                  isInvalid={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  type="number"
                  name="phoneNumber"
                  placeholder="Enter your phone Number"
                  {...getFieldProps("phoneNumber")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                isInvalid={touched.password && Boolean(errors.password)}
                type="password"
                name="password"
                placeholder="Enter Password"
                {...getFieldProps("password")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                isInvalid={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                type="password"
                name="confirmPassword"
                placeholder="Enter Confirm Password"
                {...getFieldProps("confirmPassword")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                isInvalid={touched.dob && Boolean(errors.dob)}
                type="date"
                name="dob"
                placeholder="Select Date of birth"
                {...getFieldProps("dob")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dob}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Enter Address</Form.Label>
              <Form.Control
                ref={addressRef}
                isInvalid={touched.address && Boolean(errors.address)}
                type="text"
                name="address"
                placeholder="Enter your address here"
                onBlur={handleBlur}
                onChange={(e) => {
                  if (!e.target.value) {
                    setAddressComponent(undefined);
                  }
                  handleChange(e);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Form.Group>
            {addressComponent && (
              <div className="my-3">
                <AddressDetailTable {...addressComponent} />
              </div>
            )}
            <CustomButton
              loading={loading}
              btnText="Proceed"
              onClick={submitForm}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyRegistrationForm;
